import React from 'react';
import './aboutus.css';

const AboutUS = () => {
  return (
    <div className="about" id="about">
      <h1 className="about-title99" data-aos="fade-up" data-aos-once="true">
        About Us
      </h1>

      <div className="content-container99">
        <div className="imag" 
          data-aos="fade-right" 
          data-aos-easing="linear" 
          data-aos-duration="1000" 
          data-aos-once="true">
        </div>
        <div className="text-container" 
          data-aos="fade-left" 
          data-aos-easing="linear" 
          data-aos-duration="1000" 
          data-aos-once="true">
          <div className="about_heading">
            Public Policy and Opinion Cell IIT Kanpur is the first student-run public policy think-tank among all 23 IITs across the nation.
          </div>
          <div className="about_main">
            PPOC conducts enlightening discussions and workshop sessions among students’ community regarding various socio-economic and political issues of national and international interest. Apart from the regular sessions, we regularly publish blogs, newsletters, and research articles on these important contemporary issues. These activities earn a tremendously optimistic rejoinder from the student's group and conclude with great notions.
          </div><br />
          <a href="/events">
            <button className="read-more-button">Read More</button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default AboutUS;
