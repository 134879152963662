import React, { useState, useEffect } from 'react';
import './home.css';
import Navbar from './navbar';

const Home = () => {
  const [videoSrc, setVideoSrc] = useState('/videos/banner.mp4');

  useEffect(() => {
    const updateVideoSource = () => {
      const width = window.innerWidth;
      if (width < 768) {
        setVideoSrc('/videos/phone banner.mp4'); // Mobile view
      } else if (width < 1024) {
        setVideoSrc('/videos/tablet banner.mp4'); // Tablet view
      } else {
        setVideoSrc('/videos/banner.mp4'); // Desktop view
      }
    };

    // Initial check
    updateVideoSource();

    // Add event listener for resizing
    window.addEventListener('resize', updateVideoSource);

    // Cleanup listener on component unmount
    return () => {
      window.removeEventListener('resize', updateVideoSource);
    };
  }, []);

  return (
    <>
      <div className="home22" id="home">
        <div className="background-video-container22">
          <video
            className="background-video22"
            src={videoSrc}
            autoPlay
            muted
            playsInline
            onEnded={(e) => e.target.style.display = ''} // Hide video after playback
          ></video>
          <Navbar />
        </div>
      </div>
    </>
  );
};

export default Home;
