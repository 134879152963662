import React, { useEffect } from 'react';
import './PolicyConclave.css';
import './Policy Conclave/section.css';
import Home from './Policy Conclave/Home';
import Section2 from './Policy Conclave/Section2';
import Section3 from './Policy Conclave/WhatWedo';
import Event from './Policy Conclave/event';
import PastSpeaker from './Policy Conclave/PastSpeaker';
import ThemePage from './Policy Conclave/theme';
import Merch from './Policy Conclave/merchandise';
// import FooterPC from './Policy Conclave/footer'

export default function PolicyConclave({ setShowNavbarAndFooter }) {
  useEffect(() => {
    setShowNavbarAndFooter(false); // Hide the default navbar and footer for this page

    return () => {
      setShowNavbarAndFooter(true); // Restore default navbar and footer on unmount
    };
  }, [setShowNavbarAndFooter]);

  return (
    <>
      <Home />
      <Section2 />
      <Section3 />
      <ThemePage />
      <Event />
      <Merch />
      <PastSpeaker />
      {/* <FooterPC/> */}
    </>
  );
}
