// import React from 'react';
// import './PastSpeaker.css';

// const speakers = [
//   { name: 'Derek-O-Brien', designation: 'Member of Rajya Sabha', image: 'https://i.postimg.cc/6TCMTZCN/derek-O-brien.jpg' },
//   { name: 'Subramanian Swamy', designation: 'Member of Rajya Sabha', image: 'https://i.postimg.cc/K37fVXmY/dr-subramanian-swamy.jpg' },
//   { name: 'Piyush Goyal', designation: 'Cabinet Minister', image: 'https://i.postimg.cc/xcYtmdPN/piyush-goyal.jpg' },
//   { name: 'Tanu Jain', designation: 'Founder of Tathastu', image: 'https://i.postimg.cc/hJJrnQK6/tanu-jain.jpg' },
//   { name: 'Navika Kumar', designation: 'Journalist', image: 'https://i.postimg.cc/S2MD625C/navika-kumar.jpg' },
//   { name: 'Shweta Singh', designation: 'News Anchor', image: 'https://i.postimg.cc/8fgwtkQ3/shweta-singh.jpg' },
//   { name: 'Rajat Sharma', designation: 'Chairman, IndiaTV', image: 'https://i.postimg.cc/tsv58NGF/rajat-sharma.jpg' },
//   { name: 'Saurabh Dwivedi', designation: 'Chief Editor, The Lallantop', image: 'https://i.postimg.cc/CZW4xM11/saurabh-dwivedi.jpg' }
// ];

// const PastSpeaker = () => {
//   return (
//     <div className="past-speakers" id='pastspeaker'>
//       <div className="backgroundimage">
//         <h1 className="past-speakers-heading">PAST SPEAKERS</h1>
//         <div className="cards">
//           {speakers.map((speaker, index) => (
//             <div className="card" key={index}>
//               <img src={speaker.image} alt={speaker.name} className="speaker-img" />
//               <div className="overlay">
//                 <div className="info">
//                   <h3>{speaker.name}</h3>
//                   <p>{speaker.designation}</p>
//                 </div>
//               </div>
//             </div>
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default PastSpeaker;

import React from 'react';
import './PastSpeaker.css';

const speakers = [
  { name: 'Derek-O-Brien', designation: 'Member of Rajya Sabha', image: 'https://i.postimg.cc/6TCMTZCN/derek-O-brien.jpg' },
  { name: 'Subramanian Swamy', designation: 'Member of Rajya Sabha', image: 'https://i.postimg.cc/K37fVXmY/dr-subramanian-swamy.jpg' },
  { name: 'Piyush Goyal', designation: 'Cabinet Minister', image: 'https://i.postimg.cc/xcYtmdPN/piyush-goyal.jpg' },
  { name: 'Tanu Jain', designation: 'Founder of Tathastu', image: 'https://i.postimg.cc/hJJrnQK6/tanu-jain.jpg' },
  { name: 'Navika Kumar', designation: 'Journalist', image: 'https://i.postimg.cc/S2MD625C/navika-kumar.jpg' },
  { name: 'Shweta Singh', designation: 'News Anchor', image: 'https://i.postimg.cc/8fgwtkQ3/shweta-singh.jpg' },
  { name: 'Rajat Sharma', designation: 'Chairman, IndiaTV', image: 'https://i.postimg.cc/tsv58NGF/rajat-sharma.jpg' },
  { name: 'Saurabh Dwivedi', designation: 'Chief Editor, The Lallantop', image: 'https://i.postimg.cc/CZW4xM11/saurabh-dwivedi.jpg' }
];

const PastSpeaker = () => {
  return (
    <div className="past-speakers-PCo" id="pastspeaker">
      <div className="backgroundimage-PCo">
        <h1 className="past-speakers-heading-PCo">PAST SPEAKERS</h1>
        <div className="cards-PCo">
          {speakers.map((speaker, index) => (
            <div className="card-PCo" key={index}>
              <img src={speaker.image} alt={speaker.name} className="speaker-img-PCo" />
              <div className="overlay-PCo">
                <div className="info-PCo">
                  <h3>{speaker.name}</h3>
                  <p>{speaker.designation}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PastSpeaker;
