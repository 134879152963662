// // // import React from 'react';
// // // import "./footer.css";

// // // export default function FooterPC() {
// // //   return (
// // //     <div className="footerPC">
// // //       <div className="footer-titlePC">
// // //         <img className="linesPC" src="/Line 18.svg" alt="line" />
// // //         <img className="titlePC" src="/footer logo.png" alt="footer logo" />
// // //       </div>
// // //       <div className="contentPC">
// // //         <div className="col1PC">
// // //           <h5>Quick Links</h5>
// // //           <a href="https://www.iitk.ac.in/">IIT Kanpur</a><br />
// // //           <a href="/">PPOC</a><br />
// // //           <a href="/">Policy Conclave</a><br />
// // //         </div>
// // //         <div className="col2PC">
// // //           <h5>More Links</h5>
// // //           <a href="/blog">Articles</a><br />
// // //           <a href="/events">Events</a><br />
// // //           <a href="/projects">Projects</a><br />
// // //         </div>
// // //         <div className="col3PC">
// // //           <h5>CONTACT US</h5>
// // //           <p>
// // //             Indian Institute of Technology Kanpur,<br />
// // //             Uttar Pradesh, 208016<br />
// // //             Email: <a href="mailto:ppoc@iitk.ac.in">ppoc@iitk.ac.in</a>
// // //           </p>
// // //         </div>
// // //       </div>
// // //       <div>
// // //         <div className="icon-containerPC">
// // //           <a href="https://www.facebook.com/ppoc.iitk"><img src="/Facebook (2).svg" alt="Facebook" /></a>
// // //           <a href="https://www.linkedin.com/company/ppoc-iitk/mycompany/"><img src="/LinkedIn.svg" alt="LinkedIn" /></a>
// // //           <a href="https://www.instagram.com/ppoc_iitk/"><img src="/Instagram.svg" alt="Instagram" /></a>
// // //           <a href="https://www.youtube.com/channel/UCL6N8QXTgLIdj2SzxYhHrag"><img src="/Vector.svg" alt="YouTube" /></a>
// // //         </div>
// // //         <img className="linesPC" src="/Line 18.svg" alt="line" />
// // //         <p className="pPC">Copyright © 2024-25 PPOC, IIT Kanpur | Designed by Web Team</p>
// // //       </div>
// // //     </div>
// // //   );
// // // }
// import React from 'react';
// import "./footer.css";

// export default function Footer() {
//   return (
//     <div className="footer33">
//       <br></br>
//       <div className="content33">
//         <div className="col133">
//           <h5>Quick Links</h5>
//           <a href="https://www.iitk.ac.in/">IIT Kanpur</a><br/>
//           <a href="/">PPOC</a><br/>
//           <a href="/">Policy Conclave</a><br/>
//         </div>
//         <div className="col233">
//           <h5>More Links</h5>
//           <a href="/blog">Articles</a><br/>
//           <a href="/events">Events</a><br/>
//           <a href="/projects">Projects</a><br/>
//         </div>
//         <div className="col333">
//           <h5>CONTACT US</h5>
//           <p>Indian Institute of Technology Kanpur, Uttar Pradesh, 208016<br/>Email: ppoc@iitk.ac.in</p>
//         </div>
//       </div>
//       <div>
//         <div className="icon-container33">
//           <a href="https://www.facebook.com/ppoc.iitk"><img src="/Facebook (2).svg" alt="Facebook"/></a>
//           <a href="https://www.linkedin.com/company/ppoc-iitk/mycompany/"><img src="/LinkedIn.svg" alt="LinkedIn"/></a>
//           <a href="https://www.instagram.com/ppoc_iitk/"><img src="/Instagram.svg" alt="Instagram"/></a>
//           <a href="https://www.youtube.com/channel/UCL6N8QXTgLIdj2SzxYhHrag"><img src="/Vector.svg" alt="YouTube"/></a>
//         </div>
//         <p className="p33">Copyright © 2024-25 PPOC, IIT Kanpur | Designed by Web Team</p>
//       </div>
//     </div>
//   );
// }

import React from 'react';
import "./footer.css";

export default function Footer() {
  return (
    <div className="footer33">
      <br></br>
      <div className="content33">
        <div className="logo-container33">
          <img src="https://i.postimg.cc/gcCFhZKw/logo-2.png" alt="Logo" className="logo33" />
        </div>
        <div className="col133">
          <h5>Quick Links</h5>
          <a href="https://www.iitk.ac.in/">IIT Kanpur</a><br />
          <a href="/">PPOC</a><br />
          <a href="/">Policy Conclave</a><br />
        </div>
        <div className="col233">
          <h5>More Links</h5>
          <a href="/blog">Articles</a><br />
          <a href="/events">Events</a><br />
          <a href="/projects">Projects</a><br />
        </div>
        <div className="col333">
          <h5>CONTACT US</h5>
          <p>Indian Institute of Technology Kanpur, Uttar Pradesh, 208016<br />Email: ppoc@iitk.ac.in</p>
        </div>
      </div>
      <div>
        <div className="icon-container33">
          <a href="https://www.facebook.com/ppoc.iitk"><img src="/Facebook (2).svg" alt="Facebook" /></a>
          <a href="https://www.linkedin.com/company/ppoc-iitk/mycompany/"><img src="/LinkedIn.svg" alt="LinkedIn" /></a>
          <a href="https://www.instagram.com/ppoc_iitk/"><img src="/Instagram.svg" alt="Instagram" /></a>
          <a href="https://www.youtube.com/channel/UCL6N8QXTgLIdj2SzxYhHrag"><img src="/Vector.svg" alt="YouTube" /></a>
        </div>
        <p className="p33">Copyright © 2024-25 PPOC, IIT Kanpur | Designed by Web Team</p>
      </div>
    </div>
  );
}
