// import React from 'react';
// import './section.css';

// export default function Section2() {
//   return (
//     <div className="section2-container" id="about">
//       {/* Background Video */}
//       <video className="background-video" autoPlay loop muted>
//         <source src="/videos/bg_video.mp4" type="video/mp4" />
//         Your browser does not support the video tag.
//       </video>

//       {/* Content Overlay */}
//       <div className="section2-content">
//         <h1 className="section2-title">POLICY CONCLAVE</h1>
//         <p className="section2-text">
//           Policy Conclave is the premier annual event of the Public Policy and Opinion Club (PPOC), IIT Kanpur. 
//           Held every January, it provides a comprehensive platform to engage students, professionals, and thought 
//           leaders in the realm of policymaking and public discourse. Over three vibrant days, the event features 
//           an exciting lineup of:
//         </p>
//         <ul className="section2-list">
//           <li>Interactive webinars by distinguished policymakers and scholars.</li>
//           <li>Competitions to hone analytical and problem-solving skills.</li>
//           <li>Workshops to delve deep into real-world policy challenges.</li>
//         </ul>
//         <p className="section2-text">
//           Policy Conclave inspires participants to explore the intricacies of governance, economics, and public 
//           administration. It serves as a one-stop destination for college students who aspire to make a meaningful 
//           impact in shaping public opinion and its implementation in society.
//         </p>
        
//       </div>
//     </div>
//   );
// }

import React from 'react';
import './section.css';

export default function Section2() {
  return (
    <div className="section2-container-PoCo" id="about">
      {/* Background Video */}
      <video className="background-video-PoCo" autoPlay loop muted>
        <source src="/videos/Untitled.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      {/* Content Overlay */}
      <div className="section2-content-PoCo">
        <h1 className="section2-title-PoCo">POLICY CONCLAVE</h1>
        <p className="section2-text-PoCo">
          Policy Conclave is the premier annual event of the Public Policy and Opinion Club (PPOC), IIT Kanpur. 
          Held every January, it provides a comprehensive platform to engage students, professionals, and thought 
          leaders in the realm of policymaking and public discourse. Over three vibrant days, the event features 
          an exciting lineup of:
        </p>
        <ul className="section2-list-PoCo">
          <li>Interactive webinars by distinguished policymakers and scholars.</li>
          <li>Competitions to hone analytical and problem-solving skills.</li>
          <li>Workshops to delve deep into real-world policy challenges.</li>
        </ul>
        <p className="section2-text-PoCo">
          Policy Conclave inspires participants to explore the intricacies of governance, economics, and public 
          administration. It serves as a one-stop destination for college students who aspire to make a meaningful 
          impact in shaping public opinion and its implementation in society.
        </p>
      </div>
    </div>
  );
}
