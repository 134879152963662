import React, { useState } from 'react';
import './event.css';

const Event = () => {
  const [selectedEvent, setSelectedEvent] = useState(null); // State to track selected event

  const events = [
    {
      imgSrc: 'https://i.postimg.cc/XvJnvFYr/2.jpg',
      title: 'RAJNEETI',
      link: 'https://unstop.com/competitions/rajneeti-a-political-case-study-competition-by-iit-kanpur-policy-conclave25-annual-flagship-event-ppoc-iit--1299690',
      description: 'Rajneeti: A dynamic political case study competition, unfolded within the grandeur of Policy Conclave 25. This collaboration featured a partnership with junta ka mood, a distinguished political consultancy firm. Entrants grappled with intricate political challenges, crafting innovative strategies to solve them. The event witnessed an overwhelming response, showcasing a collective enthusiasm for insightful political discourse.'
    },
    {
      imgSrc: 'https://i.postimg.cc/Jh2MHnsP/3.jpg',
      title: 'ECOCRAFT',
      link: 'https://unstop.com/competitions/ecocraft-sustainable-product-challenge-policy-conclave25-annual-flagship-event-ppoc-iit-kanpur-iit-kanpur-1296793',
      description: 'EcoCraft: A competition focused on sustainable solutions, where participants had to design and present innovative products that could tackle pressing environmental challenges. This flagship event brought forward creative minds working towards sustainability, with hands-on projects that reflect real-world ecological issues.'
    },
    {
      imgSrc: 'https://i.postimg.cc/vZXQMXbR/1.jpg',
      title: 'VIDESH NEETI',
      link: 'https://unstop.com/competitions/videsh-neeti-a-foreign-diplomacy-case-study-challenge-policy-conclave25-annual-flagship-event-ppoc-iit-kanp-1296701?lb=pb4DRK6K&utm_medium=Share&utm_source=shortUrl',
      description: 'Videsh Neeti: An enthralling case study competition that explores geopolitical issues. The event consists of 3 rounds: an insightful quiz on international relations, a video submission on pressing global challenges, and a spot round to test participants’ ability to think on their feet. It celebrates diplomacy and strategic thinking in the ever-changing world of international policy.'
    },
    {
      imgSrc: 'https://i.postimg.cc/C1nYGgDY/4.jpg',
      title: 'MUN',
      link: 'https://unstop.com/competitions/model-united-nations-mun25-policy-conclave25-annual-flagship-event-ppoc-iit-kanpur-iit-kanpur-1306036',
      description: 'IITK MUN: A flagship event within Policy Conclave 25, where students from diverse backgrounds engage in debate and diplomacy. The All India Political Parties Meet (AIPPM) committee discussed the critical issue of unemployment amidst a growing population. The MUN proved to be a resounding success, fostering diplomatic skills and critical thinking in the delegates.'
    }
  ];

  const handleEventClick = (event) => {
    setSelectedEvent(event); // Set the selected event for description popup
  };

  const closePopup = () => {
    setSelectedEvent(null); // Close the popup by resetting the selected event
  };

  return (
    <div className="events-container44" id="pevents">
      <h1 className="events-heading44">Events & Competitions</h1>
      <div className="event-cards-container44">
        {events.map((event, index) => (
          <div key={index} className="event-card44">
            <div className="event-card-img44">
              <img src={event.imgSrc} alt={event.title} />
            </div>
            <div className="event-card-content44">
              <h3
                className="event-title44"
                onClick={() => handleEventClick(event)} // Display description on title click
              >
                {event.title}
              </h3>
              <a href={event.link} className="event-register-btn44" target="_blank" rel="noopener noreferrer">
                Register
              </a>
            </div>
          </div>
        ))}
      </div>

      {/* Popup for event description */}
      {selectedEvent && (
        <div className="event-description-popup44">
          <div className="popup-content44">
            <h3>{selectedEvent.title}</h3>
            <p>{selectedEvent.description}</p>
            <button className="close-popup-btn44" onClick={closePopup}>
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Event;
