import React from "react";
import "./article.css";
export default function () {
  return (
    <article class="blog-single">
      {/* <!--Hero Section starts--> */}
      <div
        class="page-header page-header--single"
        style={{
          "background-image":
            "url(https://i.postimg.cc/43LVd0G9/Article.jpg)",
        }}
      >
        <div class="row page-header__content">
          <div class="col-full">
            <h1 class="display-1">
              Abrogation of Article 370
            </h1>
            <ul class="page-header__meta">
              <li class="author">
                By: <a href="#">Prathvi Rathore, Aniket Shivhare, Raghubir Singh, Peeyush Agarwal, Vineet Nagrale</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/* <!--Hero Section Ends--> */}
      <div class="blog-content-wrap">
        <div class="row blog-content">
          <div class="col-full blog-content__main">
            <blockquote>
              <p>
                Disclaimer: The views and the opinion expressed in this article
                are those of the author and does not necessarily reflect the
                views and positions of Public Policy and Opinion Cell IITK.
              </p>
            </blockquote>
            <br />
            <h2>Abstract</h2>
            <p>
            Jammu and Kashmir is an administrative division of the Union of India. The current union territory had been a disputed territory
            since its accession to Republic of India in 1947 between India and Pakistan. The larger state which existed from 1947 to 2019 was
            divided into two union territories in 2019 by the Government of India between the Union Territory of Jammu and Kashmir and
            Union territory of Ladakh. This working paper provides an analysis of what changed in the Union Territory Jammu and Kashmir
            after the abrogation of article 370 of the Indian Constitution, which provided a special status to the state enabling a different
            constitution for the state and hindering the parliament from making laws for the territories.
            </p>

            <p>
            In August 2019, Indian Government introduced the abrogation of every section of Article 370 of the Indian Constitution
            except the first one. The move was accompanied by tight security conditions and placing various local secessionist and
            political leaders under home custody. After the abrogation, the military forces launched several operations
            to eliminate various threats of terrorism from the state. This moves were criticized by various global leaders and institutions,
            with foreign governments also showing their concern for human rights violations.
            </p>
            
            <p>
            This paper explores the central government's developmental policies aimed at fostering economic growth, improving
            infrastructure, and enhancing the quality of life for the residents of Jammu and Kashmir
            Key initiatives discussed include significant infrastructure projects, efforts to attract investment and promote industrial growth,
            and the revitalization of the tourism sector. The paper also examines social policies in healthcare, education, and employment,
            highlighting the region's strides toward modernization and inclusivity.This study aims to provide a nuanced understanding of
            the region's journey from turmoil to a path of development and progress in the postArticle 370 era.
            </p>
            <a href="\370_article.pdf" class="blog-btn">Read Full Article</a> <br/>
          </div>
        </div>
      </div>
    </article>
  );
}
