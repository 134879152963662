import React from 'react';
import './theme.css';

const ThemePage = () => {
  return (
    <div className="theme-container21" id='theme'>
      
      {/* Computer Screen with Video */}
      <div className="computer-screen21">
        <video
          className="theme-video21"
          autoPlay
          loop
          muted
          src="/videos/theme_vid.mp4" type="video/mp4"
        ></video>
      </div>
      
      {/* Text Content */}
      <div className="theme-text21">
        <h1 className="main-theme21">Policy Conclave: Epoch Nexus</h1>
        <h2 className="sub-theme21">Celebrating 75 Years of India's Constitution</h2>
        <p className="description21">
          As we commemorate 75 glorious years since the adoption of the Indian Constitution, 
          <i> Epoch Nexus: Policy Conclave 2025</i> stands as a beacon of reflection, 
          dialogue, and vision for India's policy landscape. This momentous milestone invites policymakers, 
          academics, students, and thought leaders to converge, discuss, and ideate the evolving fabric 
          of governance, democracy, and nation-building in India.
        </p>
        <h3 className="why-epoch21">Why 'Epoch Nexus'?</h3>
        <p className="description21">
          The theme encapsulates the crossroads of India's policy journey — a seamless blend of historical achievements, 
          current realities, and the pathways to a sustainable and inclusive future. From the aspirations embedded in 
          the Preamble to the lived experiences of millions, the conclave delves into the Constitution's enduring legacy 
          and its dynamic relevance.
        </p>
      </div>
    </div>
  );
};

export default ThemePage;
