import React, { useState } from 'react';
import './merchandise.css';

const Merchandise = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalImage, setModalImage] = useState('');

  const handleImageClick = (imageSrc) => {
    setModalImage(imageSrc);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="merchandise-container11" id="merchandise">
      <h1 className="merchandise-heading11">Official Merchandise</h1>
      <div className="merchandise-item11">
        <div className="merchandise-image11">
          <img
            src="https://i.postimg.cc/66P7qy5V/merch-3.png"
            alt="PPOC Merchandise"
            onClick={() => handleImageClick('https://i.postimg.cc/66P7qy5V/merch-3.png')}
          />
        </div>
        <div className="merchandise-content11">
          <p>
            Get your hands on the official Policy Conclave'25 merchandise! Perfect for showing your support for the event.
          </p>
          <a
            href="https://docs.google.com/forms/d/1JU7s3Hwg9Gg4A0P-NuGpTtpap4bp9ZiudAqhmDKwP9o/edit"
            className="buy-now-btn11"
            target="_blank"
            rel="noopener noreferrer"
          >
            BUY NOW
          </a>
        </div>
      </div>

      {/* Modal */}
      {isModalOpen && (
        <div className="modal-overlay11" onClick={handleCloseModal}>
          <div className="modal-content11" onClick={(e) => e.stopPropagation()}>
            <img src={modalImage} alt="Modal Image" className="modal-image11" />
            <button className="close-btn11" onClick={handleCloseModal}>X</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Merchandise;
