import React, { useState } from 'react';
import './navbar.css';

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleLinkClick = () => {
    setIsMenuOpen(false);
  };

  return (
    <nav className="navbarPPC">
      <div className="navbar-containerPPC">
        <div className="navbar-logoPPC">
          <img
            src="https://i.postimg.cc/gcCFhZKw/logo-2.png"
            alt="PPOC Logo"
          />
        </div>
        <div className={`navbar-linksPPC ${isMenuOpen ? 'openPPC' : ''}`}>
          <ul>
            <li>
              <a href="#home" onClick={handleLinkClick}>
                Home
              </a>
            </li>
            <li>
              <a href="#about" onClick={handleLinkClick}>
                About
              </a>
            </li>
            <li>
              <a href="#theme" onClick={handleLinkClick}>
                Theme
              </a>
            </li>
            <li>
              <a href="#pevents" onClick={handleLinkClick}>
                Events & Competitions
              </a>
            </li>
            <li>
              <a href="#merchandise" onClick={handleLinkClick}>
                Merchandise
              </a>
            </li>
            <li>
              <a href="#pastspeaker" onClick={handleLinkClick}>
                Past Speakers
              </a>
            </li>
          </ul>
        </div>
        <div
          className="navbar-togglePPC"
          onClick={() => setIsMenuOpen(!isMenuOpen)}
        >
          <div className="toggle-barPPC"></div>
          <div className="toggle-barPPC"></div>
          <div className="toggle-barPPC"></div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
