import React from 'react';
import './WhatWeedo.css'

const SectionCard = ({ image, title, description }) => {
  return (
    <div className="section-card12">
      <img src={image} alt={title} className="section-image12" />
      <div className="section-text12">
        <h3>{title}</h3>
        <p>{description}</p>
      </div>
    </div>
  );
};

export default function WhatWedo() {
  return (
    <section className="psection pevent12" id="">
      <div className="background-image112">
        <div className="text-center12">
          <h1 className="phead12">Our Mission in Action</h1>
        </div>
        <div className="section-wrapper12">
          <SectionCard
            image="Gallery/img1.jpeg"
            title="Talks"
            description="We host enlightening talks by eminent personalities and engaging discussions to bring a positive social impact. It's an attempt to engage the students with the challenges of policy-making in contemporary society by fostering, creating, and scrutinizing potential policy solutions to a diverse range of critical societal issues."
          />
          <SectionCard
            image="Gallery/img3.jpeg"
            title="Panel Discussions"
            description="The Public Policy and Opinion Cell organize much engaging panel discussions, which feature many esteemed speakers. One such event included Mrs. Navika Kumar, Editor-in-Chief of Times Now Navbharat. The focus of the event was on the crucial role of journalism in the democratic process, particularly during elections."
          />
          <SectionCard
            image="Gallery/img2.png"
            title="Cultural Events"
            description="We organize vibrant cultural events that celebrate the rich diversity of traditions. These events aim to foster unity and provide a platform for students to showcase their artistic talents. The cultural celebrations include music, dance, poetry, and other performances, enriching the campus experience and promoting intercultural understanding."
          />
        </div>
      </div>
    </section>
  );
}
